@import '../../../../variables.css';

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.container {
    padding: 80px;
}

.mainTitle {
    margin-bottom: 40px;
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
}

.columns {
    display: grid;
    grid-template-columns: auto 1fr 310px;
    gap: 40px;
}

.columns>img {
    width: 292px;
    height: 289px;
}

.annualFeatures {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 38px;
}

.annualFeature {
    display: flex;
    align-items: center;
    gap: 13px;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-34-line-height);
}

.featureContainer {
    align-self: baseline;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.featureContainer>p {
    text-align: left;
    font-size: var(--ark-24-font-size);
    line-height: var(--ark-34-line-height);
}

@media screen and (max-width: var(--ark-medium-desktop-figma-1366)) {
    .mainTitle {
        max-width: 924px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 1136px) {
    .container {
        padding: 32px 24px;
    }

    .mainTitle {
        font-size: var(--ark-26-font-size);
        line-height: var(--ark-36-line-height);
    }

    .annualFeature {
        font-size: var(--ark-22-font-size);
        line-height: var(--ark-31-line-height);
    }

    .columns {
        grid-template-columns: 1fr 1fr;
        gap: 28px;
    }

    .columns>img {
        display: none;
    }

    .featureContainer>p {
        font-size: var(--ark-22-font-size);
        line-height: var(--ark-31-line-height);
    }
}

@media screen and (max-width: 700px) {
    .container {
        padding: 32px 16px;
    }

    .mainTitle {
        width: 288px;
        margin: auto;
        margin-bottom: 40px;
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-34-line-height);
    }

    .columns {
        grid-template-columns: 1fr;
        width: 288px;
        margin: auto;
        gap: 32px;
    }

    .annualFeature {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }

    .annualFeature img {
        width: 59.5px;
        height: 40px;
    }

    .featureContainer>p {
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }
}