@import '../../../../variables.css';

.features {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 16px;
}

.feature {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 8px;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-25-line-height);
}

.feature.hidden:not(.regularBullets) {
    color: var(--ark-secondary-solid-midgrey);
}

.feature.hidden img {
    visibility: hidden;
}

.feature.regularBullets {
    display: list-item;
    list-style: disc;
    margin-left: 20px;
}

.feature.regularBullets img {
    display: none;
}

@media screen and (max-width: 1266px) {
    .feature {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
    }
}